const {
  useRef,
  useEffect
} = React;
const {
  area,
  api,
  branding,
  Confirm,
  type
} = svs.ui.dialog;
const Dialog = ({
  icon,
  severe,
  title,
  buttonTitle,
  buttonCallback: callback,
  message
}) => {
  const dialog = useRef(null);
  useEffect(() => {
    if (dialog.current) {
      dialog.current.close();
    }
    dialog.current = new Confirm({
      actions: [{
        callback,
        title: buttonTitle
      }],
      area: area.POPUP,
      branding: branding.SPORT,
      message: [{
        text: message
      }],
      title,
      icon,
      type: severe ? type.ERROR : type.DEFAULT
    });
    api.add(dialog.current);
    return () => {
      if (dialog.current) {
        dialog.current.close();
      }
    };
  }, []);
  return null;
};
Dialog.propTypes = {
  buttonCallback: PropTypes.func,
  buttonTitle: PropTypes.string,
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
  severe: PropTypes.bool,
  title: PropTypes.string
};
export default Dialog;